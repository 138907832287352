<template>
  <div class="text-field mb-3">
    <div
      class="title-text mb-3"
    >
      {{ title }}
    </div>
    <div class="d-flex align-center">
      <label
        :for="id"
        class="custom-file-upload mr-3"
      >
        <div class="file__upload d-flex py-2 px-4">
          <v-progress-circular
            v-if="isShowUploadProgress"
            color="secondary"
            indeterminate
            size="16"
            width="2"
          />
          <div v-else>
            <img
              class="mt-1 mr-3"
              :src="require('@/assets/images/icon/upload-copy.svg')" 
            >
            <span>อัพโหลดไฟล์</span>
          </div>
        </div>
      </label>
      <span
        v-if="hasErrorMessage"
        class="error-text ml-3 mt-1"
      >
        {{ errorMessage }}
      </span>
      <span>
        {{ fileName }}
      </span>
    </div>
    <input
      :id="id"
      class="file__upload__input"
      type="file"
      @change="onFileChange"
    >
  </div>
</template>

<script>
export default {
  name: 'RegisterFileUpload',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: () => '',
    },
    fileName: {
      type: String,
      default: () => '',
    },
    errorMessage: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    isShowUploadProgress: false,
  }),
  computed: {
    errorMessageValue: {
      get() {
        return this.errorMessage;
      },
      set(value) {
        this.$emit('setErrorMessage', value);
      },
    },
  },
  methods: {
    async onFileChange(e) {
      this.isShowUploadProgress = true;
      setTimeout(() => {
        this.$emit('setValue', e.target.files[0]);
        this.$emit('setErrorMessage', '');
        this.isShowUploadProgress = false;
      }, 1000);
    },
    hasErrorMessage() {
      return !!this.errorMessage;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.file__upload__input {
  display: none;
}

.file__upload {
  @include kanit;
  cursor: pointer;
  max-width: 135px;
  font-size: 14px;
  line-height: 1.57;
  color: #751edf;
  border-radius: 4px;
  border: solid 1px #751edf;
}

.error-text {
  @include kanit;
  font-size: 14px;
  color: $red-color-01;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #242424;
}

</style>