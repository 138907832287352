<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="text-field">
        <div
          class="title-text mb-3"
        >
          {{ title }}
        </div>
        <v-text-field
          :value="computedDateFormatted"
          class="field"
          outlined
          v-bind="attrs"
          prepend-inner-icon="mdi-calendar-clock"
          v-on="on"
        />
      </div>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import { formatDateWithoutTime } from "@/helpers/date";

export default {
  name: 'RegisterDatePicker',
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
  }),
  computed: {
    computedDateFormatted() {
      this.$emit('setValue', this.date);
      return this.formatDate(this.date);
    },
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return null;
      return formatDateWithoutTime(dateStr);
    },
  },
};
</script>

<style lang="scss">

.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-top: 10px;
  margin-right: 4px;
}

</style>