<template>
  <v-container class="d-flex flex-column align-center max-width-450 pt-16 mb-16 mt-16">
    <RegisterHeader />
    <div class="mt-8 mt-md-0" />
    <span class="title-text">{{ title }}</span>
    <v-row class="full-width mt-2 mb-12">
      <v-form
        ref="form"
        v-model="isFormValid"
        class="full-width"
        lazy-validation
      >
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="customerName"
            :rules="customerNameRules"
            title="ชื่อ - นามสกุล"
            place-holder="ชื่อ - นามสกุล"
            @setValue="setCustomerName"
          />
        </v-col>
        <!--
        <v-col
          cols="12"
          class="py-0"
        >
          <div class="text-field">
            <div class="title-text">
              ประเภทสมาชิก
            </div>
            <v-radio-group
              v-model="level"
              mandatory
              class="level-input mt-1"
            >
              <v-radio
                label="0 - ลูกค้าปลีก"
                value="0"
              />
              <v-radio
                label="1 - ลูกค้าส่ง"
                value="1"
              />
            </v-radio-group>
          </div>
        </v-col>
        -->
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            v-if="level != 0"
            :value="storeName"
            :rules="storeNameRules"
            title="ชื่อสถานประกอบการ"
            place-holder="ชื่อสถานประกอบการ"
            @setValue="setStoreName"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-column py-0"
        >
          <template>
            <ThailandAutoComplete
              v-model="postCode"
              type="zipcode"
              size="medium"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              @select="setThailandAutoComplete"
              :class="isPostCodeError ? 'input-error' : ''"
            />
            <span
              v-if="isPostCodeError"
              class="input-error-message"
            >
              กรุณากรอกรหัสไปรษณีย์
            </span>
            <ThailandAutoComplete
              v-model="province"
              type="province"
              size="medium"
              label="จังหวัด"
              placeholder="จังหวัด"
              @select="setThailandAutoComplete"
              :class="isProvinceError ? 'input-error' : ''"
            />
            <span
              v-if="isProvinceError"
              class="input-error-message"
            >
              กรุณากรอกจังหวัด
            </span>
            <ThailandAutoComplete
              v-model="city"
              type="amphoe"
              size="medium"
              label="เขต / อำเภอ"
              placeholder="เขต / อำเภอ"
              @select="setThailandAutoComplete"
              :class="isCityError ? 'input-error' : ''"
            />
            <span
              v-if="isCityError"
              class="input-error-message"
            >
              กรุณากรอกเขต / อำเภอ
            </span>
            <ThailandAutoComplete
              v-model="subDistrict"
              type="district"
              size="medium"
              label="แขวง / ตำบล"
              placeholder="แขวง / ตำบล"
              @select="setThailandAutoComplete"
              :class="isSubDistrictError ? 'input-error' : ''"
            />
            <span
              v-if="isSubDistrictError"
              class="input-error-message"
            >
              กรุณากรอกแขวง / ตำบล
            </span>
          </template>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="address"
            :rules="addressRules"
            title="บ้านเลขที่ / ถนน"
            place-holder="บ้านเลขที่ / ถนน"
            @setValue="setAddress"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="customerPhone"
            title="เบอร์โทร"
            place-holder="เบอร์โทร"
            @setValue="setCustomerPhone"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="customerEmail"
            :rules="customerEmailRules"
            title="อีเมล"
            place-holder="อีเมล"
            @setValue="setCustomerEmail"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <div class="text-field">
            <div
              v-if="title"
              class="title-text mb-3"
            >
              รหัสผ่าน
            </div>
            <v-text-field
              v-model="passwordValue"
              :rules="passwordValueRules"
              class="field"
              type="password"
              outlined
              placeholder="รหัสผ่าน"
              autocomplete="new-password"
              @keyup="passwordCheck"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <div class="text-field">
            <div
              v-if="title"
              class="title-text mb-3"
            >
              ยืนยันรหัสผ่าน
            </div>
            <v-text-field
              id="confirm-new-password"
              v-model="confirmPasswordValue"
              class="field"
              type="password"
              outlined
              placeholder="ยืนยันรหัสผ่าน"
              autocomplete="confirm-new-password"
              @keyup="passwordConfirmationCheck"
              @blur="passwordConfirmationCheck"
              :error="confirmPasswordError"
              :error-messages="confirmPasswordErrorMessage"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="referralCode"
            title="โค้ดผู้แนะนำ"
            place-holder="โค้ดผู้แนะนำ"
            @setValue="setReferralCode"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="customerFacebook"
            title="Facebook"
            place-holder="Facebook"
            @setValue="setCustomerFacebook"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <TextField
            :value="customerLineId"
            title="LINE ID"
            place-holder="LINE ID"
            @setValue="setCustomerLineId"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-field py-0 mb-3"
          v-if="level != 0"
        >
          <RegisterFileUpload
            id="file-certification"
            title="ใบประกอบวิชาชีพ"
            @setValue="setFileCertification"
            @setErrorMessage="setFileCertificationErrorMessage"
            :fileName="fileCertification.name"
            :errorMessage="fileCertificationErrorMessage"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          v-if="level != 0"
        >
          <RegisterDatePicker
            title="วันหมดอายุของใบประกอบวิชาชีพ"
            @setValue="setCertificationExpiryDate"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-field py-0 mb-3"
          v-if="level != 0"
        >
          <RegisterFileUpload
            id="file-permission"
            title="ใบอนุญาต"
            @setValue="setFilePermission"
            @setErrorMessage="setFilePermissionErrorMessage"
            :fileName="filePermission.name"
            :errorMessage="filePermissionErrorMessage"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          v-if="level != 0"
        >
          <RegisterDatePicker
            title="วันหมดอายุของใบอนุญาต"
            @setValue="setPermissionExpiryDate"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex py-0 mt-5"
        >
          <v-btn
            color="#9e9e9e"
            dark
            depressed
            class="register__back__btn py-6 px-10 mr-2"
            :loading="loading"
            @click="back()"
          >
            กลับ
          </v-btn>
          <v-btn
            color="secondary"
            depressed
            class="register__submit__btn flex-grow-1 py-6 px-16"
            :loading="loading"
            @click="register()"
          >
            สมัครสมาชิก
          </v-btn>
        </v-col>
      </v-form>
    </v-row>
    <img
      :src="require('@/assets/images/background/group.svg')"
      class="background__image__left hidden-sm-and-down"
    >
    <img
      :src="require('@/assets/images/background/group-2.svg')"
      class="background__image__right hidden-sm-and-down"
    >
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince } from 'thai-address-database';
import { CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR, SHOW_SNACKBAR } from '@/store/_actionTypes';
import { createUser } from '@/services/user/user';

import Select from '@/components/Select.vue';
import TextField from '@/components/TextField.vue';
import RegisterDatePicker from './components/RegisterDatePicker.vue';
import RegisterFileUpload from './components/RegisterFileUpload.vue';
import RegisterHeader from './components/RegisterHeader.vue';

export default {
  name: 'Register',
  components: {
    Select,
    TextField,
    RegisterHeader,
    RegisterDatePicker,
    RegisterFileUpload,
  },
  data: () => ({
    loading: false,
    isFormValid: false,
    title: 'สมัครสมาชิก',
    level: 1,
    customerName: '',
    customerNameRules: [
      (v) => !!v || 'กรุณากรอกชื่อ - สกุล',
    ],
    storeName: '',
    storeNameRules: [
      (v) => !!v || 'กรุณากรอกชื่อสถานประกอบการ',
    ],
    postCode: '',
    postCodeRules: [
      (v) => !!v || 'กรุณากรอกรหัสไปรษณีย์',
    ],
    isPostCodeError: false,
    city: '',
    cityRules: [
      (v) => !!v || 'กรุณากรอกเขต / อำเภอ',
    ],
    isCityError: false,
    province: '',
    provinceRules: [
      (v) => !!v || 'กรุณากรอกจังหวัด',
    ],
    isProvinceError: false,
    address: '',
    addressRules: [
      (v) => !!v || 'กรุณากรอกบ้านเลขที่ / ถนน',
    ],
    subDistrict: '',
    subDistrictRules: [
      (v) => !!v || 'กรุณากรอก แขวง / ตำบล',
    ],
    isSubDistrictError: false,
    customerPhone: '',
    customerEmail: '',
    customerEmailRules: [
      (v) => !!v || 'กรุณากรอกอีเมล',
      (v) => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง',
    ],
    passwordValue: '',
    passwordValueRules: [
      (v) => !!v || 'กรุณากรอกรหัสผ่าน',
    ],
    confirmPasswordValue: '',
    confirmPasswordError: false,
    confirmPasswordErrorMessage: '',
    referralCode: '',
    customerFacebook: '',
    customerLineId: '',
    fileCertification: '',
    fileCertificationErrorMessage: '',
    certificationExpiryDate: '',
    filePermission: '',
    filePermissionErrorMessage: '',
    permissionExpiryDate: '',
  }),
  created() {
    this.$gtag.event('conversion', {'send_to': 'AW-966302140/4HW6CJy_l_cCELyz4swD'});
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR, CHANGE_SNACKBAR_MESSAGE ]),
    setThailandAutoComplete (address) {
      this.city = address.district
      this.subDistrict = address.amphoe
      this.province = address.province
      this.postCode = address.zipcode
      this.validateAddress();
    },
    setCustomerName(value) {
      this.customerName = value
    },
    setStoreName(value) {
      this.storeName = value
    },
    setPostCode(value) {
      this.postCode = value
    },
    setAddress(value) {
      this.address = value
    },
    setCustomerPhone(value) {
      this.customerPhone = value
    },
    setCustomerEmail(value) {
      this.customerEmail = value
    },
    setReferralCode(value) {
      this.referralCode = value
    },
    setCustomerFacebook(value) {
      this.customerFacebook = value
    },
    setCustomerLineId(value) {
      this.customerLineId = value
    },
    setCity(value) {
      const address = searchAddressByAmphoe(value, Number.MAX_SAFE_INTEGER);
      this.subDistricts = address.map(item => item.district);
      this.postCodes = address.map(item => item.zipcode);
      this.city = value;
    },
    setProvince(value) {
      const address = searchAddressByProvince(value, Number.MAX_SAFE_INTEGER);
      this.cities = address.map(item => item.amphoe);
      this.subDistricts = address.map(item => item.district);
      this.postCodes = address.map(item => item.zipcode);
      this.province = value;
    },
    setSubDistrict(value) {
      const address = searchAddressByDistrict(value, Number.MAX_SAFE_INTEGER);
      this.postCodes = address.map(item => item.zipcode);
      this.subDistrict = value;
    },
    setFileCertification(value) {
      this.fileCertification = value;
    },
    setFileCertificationErrorMessage(value) {
      this.fileCertificationErrorMessage = value;
    },
    setCertificationExpiryDate(value) {
      this.certificationExpiryDate = value;
    },
    setFilePermission(value) {
      this.filePermission = value;
    },
    setFilePermissionErrorMessage(value) {
      this.filePermissionErrorMessage = value;
    },
    setPermissionExpiryDate(value) {
      this.permissionExpiryDate = value;
    },
    passwordCheck(e) {
      if (!!this.confirmPasswordValue && this.confirmPasswordValue !== e.target.value) {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'รหัสผ่านไม่ตรงกัน';
        return;
      }

      this.confirmPasswordError = false;
      this.confirmPasswordErrorMessage = '';
    },
    passwordConfirmationCheck(e) {
      if (this.confirmPasswordValue === '') {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'กรุณากรอกยืนยันรหัสผ่าน';
        return;
      }

      if (this.passwordValue !== e.target.value) {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'รหัสผ่านไม่ตรงกัน';
        return;
      }

      this.confirmPasswordError = false;
      this.confirmPasswordErrorMessage = '';
    },
    validateFile(level) {
      if (level == 0) return true;

      const isCertificationValid = !!this.fileCertification;
      const isPermissionValid = !!this.filePermission;
      if (!isCertificationValid) {
        this.setFileCertificationErrorMessage('กรุณาอัพโหลดใบประกอบวิชาชีพ');
      }

      if (!isPermissionValid) {
        this.setFilePermissionErrorMessage('กรุณาอัพโหลดใบอนุญาต');
      }
      return isCertificationValid && isPermissionValid;
    },
    validateAddress() {
      if (!this.city) {
        this.isCityError = true;
      } else {
        this.isCityError = false;
      }

      if (!this.postCode) {
        this.isPostCodeError = true;
      } else {
        this.isPostCodeError = false;
      }

      if (!this.province) {
        this.isProvinceError = true;
      } else {
        this.isProvinceError = false;
      }

      if (!this.subDistrict) {
        this.isSubDistrictError = true;
      } else {
        this.isSubDistrictError = false;
      }

      return !this.isCityError
        || !this.isPostCodeError
        || !this.isProvinceError
        || !this.isSubDistrictError;
    },
    back() {
      this.$router.push({ name: 'Shop' });
    },
    async register() {
      this.loading = true;
      const isFileValid = this.validateFile(this.level);
      const isAddressValid = this.validateAddress();
      const isFormValid = this.$refs.form.validate();
      if (this.confirmPasswordValue === '') {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'กรุณากรอกยืนยันรหัสผ่าน';
      }

      if (!isFileValid || !isFormValid || !isAddressValid || this.confirmPasswordError) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณากรอกข้อมูลให้ครบถ้วน');
        this.loading = false;
        return;
      }

      try {
        const form = new FormData();
        form.append('data', JSON.stringify(this.getUserInformation()));
        form.append('certification_file', this.fileCertification);
        form.append('permission_file', this.filePermission);
        await createUser(form);
        this.$gtag.event('conversion', {'send_to': 'AW-966302140/i_adCPTtxYQDELyz4swD'});
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.response.data.error);
        this.loading = false;
        return;
      }

      setTimeout(() => {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        if (parseInt(this.level) === 0) {
          this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเช็คกล่องอีเมลเพื่อยืนยันตัวตน');
        } else {
          this[CHANGE_SNACKBAR_MESSAGE]('โปรดรอการอนุมัติจาก นรินเภสัช');
        }
        this.loading = false;
      }, 1500);

      setTimeout(() => {
        this.$router.push({ name: 'Shop' });
      }, 4500);
    },
    getUserInformation() {
      const userInfo = {
        fullname: this.customerName,
        store_province_name: this.province,
        store_district_name: this.city,
        store_subdistrict_name: this.subDistrict,
        store_postcode: this.postCode,
        store_address: this.address,
        phone_number: this.customerPhone,
        email: this.customerEmail,
        password: this.passwordValue,
        refer_by: this.referralCode,
        level: this.level,
        line: this.customerLineId,
        facebook: this.customerFacebook,
        certification_expired: this.certificationExpiryDate,
        permission_expired: this.permissionExpiryDate
      };

      if (this.level == 0)
        return userInfo;

      return { ...userInfo, store_name: this.storeName };
    },
  }
};
</script>

<style lang="scss">

.max-width-450 {
  max-width: 450px;
}

.register__back__btn,
.register__submit__btn {
  font-family: Kanit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: unset !important;
}

.background__image__left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.background__image__right {
  position: absolute;
  bottom: 80px;
  right: 0;
}

.file__upload {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #751edf;
}

.v-radio > label {
  font-size: 14px;
  color: #242424 !important;
}

.input-error > div > input {
  border: 2px solid #ff5252 !important;

  &:hover {
    border-color: #ff5252 !important;
  }
}

input.vth-addr-input-size-medium {
  box-shadow: unset !important;
  border-color: #a0a0a0 !important;
  border-radius: 4px !important;
  font-family: Sarabun;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.75 !important;
  color: #242424 !important;

  &:hover {
    border-color: #242424 !important;
  }

  &::-webkit-input-placeholder {
    font-family: Kanit;
    font-size: 14px;
    color: #a0a0a0;
  }
}

.vth-addr-label {
  font-family: Kanit;
  font-size: 14px;
  color: #242424 !important;
}

.input-error-message {
  margin-top: -.3rem;
  padding-left: 12px;
  margin-bottom: 12px;
  color: #ff5252;
  font-size: 12px;
}

</style>