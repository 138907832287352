<template>
  <v-row 
    no-gutters
    class="register__header d-flex"
  >
    <v-col 
      cols="12" 
      md="6" 
      class="d-flex justify-center justify-md-start"
    >
      <div class="d-flex align-center">
        <img
          width="20" 
          :src="PhoneNumberLogo" 
          class="mr-2"
        >
        <span class="telephone__text">088-886-8628</span>
      </div>
      <div class="d-flex align-center ml-4">
        <img
          width="20" 
          :src="LineLogo" 
          class="mr-2"
        >
        <span>@narinch</span>
      </div>
      <div class="d-flex align-center ml-4">
        <img
          width="20" 
          :src="FacebookLogo" 
          class="mr-2"
        >
        <span>narinbhaesaj</span>
      </div>
    </v-col>
    <v-col 
      cols="12" 
      md="6" 
      class="d-flex justify-center justify-md-end mt-2 mt-md-0"
    >
      <div class="register__header__address d-flex align-center">
        <img
          width="20" 
          :src="mapPinIcon" 
          class="mr-2"
        >
        <span>273/35 ถนนยุติธรรม ตำบลในเมือง อำเภอเมือง จังหวัดชัยภูมิ 36000  |  จ. - ส. 08:00 น. - 18:00 น.</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PhoneNumberLogo from '@/assets/images/logo/25377.svg';
import LineLogo from '@/assets/images/logo/icons-8-line_2.svg';
import FacebookLogo from '@/assets/images/logo/f-logo-rgb-blue-1024.png';
import mapPinIcon from '@/assets/images/logo/map-pin-circle.svg';

export default {
    name: 'RegisterHeader',
    data: () => ({
        PhoneNumberLogo,
        LineLogo,
        FacebookLogo,
        mapPinIcon,
    }),
};
</script>

<style lang="scss">

.register__header {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 24px 80px;
    font-size: 12px;
    color: $white-01;
    background-color: #300463;
}

.register__header__address {
    min-width: 250px;
}

.telephone__text {
    min-width: 70px;
}

</style>